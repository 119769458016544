import './App.css';
import EmpanelmentForm from './components/vendorRegistration/EmpanelmentForm';
import EditEmpanelmentForm from './components/vendorRegistration/EditEmpanelmentForm';
import VendorDataList from './components/vendorRegistration/DataList';
import Login from './components/authentication/Login';
import Layout from './components/Layout';
import { BrowserRouter , Route, Routes, Switch, Link } from 'react-router-dom'
import Thanks from "./components/vendorRegistration/Thanks"
import ThanksCOB from "./components/customer_onboarding/Thanks"
import Error from "./components/vendorRegistration/Error"
// import ProtectedRoute from './components/authentication/ProtectedRoute';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Welcome from './components/Welcome';
import NotFound from './components/NotFound';
import CustomerOnboarding from './components/customer_onboarding';
import Rough from './components/rough';
import LeadForm from './components/lead_demo';
import SubmitLead from './components/lead_demo/Submited';


function App() {
  const reqFormat = (num) => {
    return (num).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
  }
  
  const getFileId = () => {
    var newDate = new Date();
    return parseInt(newDate.getFullYear()+''+reqFormat(newDate.getMonth()+1)+''+reqFormat(newDate.getDate())+''+reqFormat(newDate.getHours())+''+reqFormat(newDate.getMinutes())+''+reqFormat(newDate.getSeconds()))
  }
  
  const handleFileUpload = async(fileInput,fileUrl) => {
    // console.log(fileInput,fileUrl)
    var formdata = new FormData();
    formdata.append("file", fileInput,fileUrl);
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
  
    const response = await fetch(process.env.REACT_APP_BASE_URL+"/file/upload", requestOptions)
    const res = await response.json()
    return res.location
  }
  
  
  
  function downloadAsDataURL (url) {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = err => reject(err)

        })
        .catch(err => reject(err))
    })
  }
  
  // simply use it like this
  const handleClick = () => {
    console.log("clicked")
    downloadAsDataURL ('https://cors-anywhere.herokuapp.com/https://s3.ap-south-1.amazonaws.com/forms.company/forms_docs/Metropolitan%20Spacespan_uploadBhavna%20Aherwar%20PAN%20CARD-converted%20%283%29.pdf')
    .then((res) => { 
      console.log(res)
      let imageString = getFileId()
      return handleFileUpload(res,imageString)
    })
    .then(resp => {
      console.log("url ::", resp )
    })
    .catch((err) => {
      console.error(err) 
    })

  }
  
  return (
    <div style={{display:"flex",justifyContent:"center"}}>
      <BrowserRouter >
      {/* <BrowserRouter basename={"/test"}> */}
          <Routes>
            <Route exact path='/' element={<Welcome handleClick={handleClick} />}/>
            <Route exact path='/admin/*' element={<Layout />} />
              {/* <Route exact path='/edit' element={<Layout ><EditEmpanelmentForm /> </Layout>}/> */}
            {/* </Route> */}
            <Route path="/auth" element={<Login />} />
            <Route path="/rough" element={<Rough />} />
            <Route exact path='/cp-registration' element={<EmpanelmentForm />}/>
            <Route exact path='/lead-demo' element={<LeadForm />}/>
            <Route exact path='/lead-demo/submited' element={<SubmitLead />}/>
            {/* <Route exact path='/customer-onboarding/:id/:applicant_name/:project_name/:plot_no/:plot_area/:special_offers/:country_code/:mobile_no/:contact_id' element={<CustomerOnboarding />}/> */}
            <Route exact path='/customer-onboarding/:bookingId' element={<CustomerOnboarding />}/>
            <Route path='/customer-onboarding/thanks' element={<ThanksCOB />} />
            <Route path='/thanks' element={<Thanks />} />
            <Route path='/error' element={<Error />} />
            {/* <Redirect to="/" /> */}
            <Route path="*" element={<NotFound/>} />
          </Routes>
        </BrowserRouter>
        <NotificationContainer/>
    </div>
  );
}

export default App;
